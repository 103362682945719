import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DxGalleryModule, DxPopupModule } from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { CarouselImageModel } from '../../../../../src/app/models/models';
import { S3FileService } from '../../../../../src/app/services/s3file-service';

@Component({
	standalone: true,
	selector: 'app-image-carousel',
	templateUrl: './image-carousel.component.html',
	styleUrls: ['./image-carousel.component.scss'],
	imports: [DxGalleryModule, CommonModule, DxPopupModule],
})
export class ImageCarouselComponent implements OnInit {
	// Input property to accept an array of image URLs
	@Input() images: CarouselImageModel[] = [];
	@Input() currentImageIndex: number = 0;
	// Optional inputs for gallery configuration
	@Input() height: string | number = '300px';
	@Input() showIndicator: boolean = true;
	@Input() showNavButtons: boolean = true;
	@Input() slideshowDelay: number = 2000;

	@Output() closeModal = new EventEmitter<void>();

	// Lightbox control variables

	isLightboxOpen: boolean = false;
	currentImage: string | null = null;
	isZoomed: boolean = false;
	imageRetryCount: { [key: string]: number } = {};

	constructor(private s3FileService: S3FileService) {}

	ngOnInit() {
		if (this.images.length > 0) {
			this.fetchImageData(this.images[0].s3FileGUID ?? '');
		}
	}
	onImageLoad(s3FileGUID: string) {
		if (this.imageRetryCount[s3FileGUID]) {
			this.imageRetryCount[s3FileGUID] = 0;
		}
	}
	onImageError(s3FileGUID: string): void {
		let index = this.images.findIndex((t) => t.s3FileGUID == s3FileGUID);
		if (this.images[index].imageData != '') {
			if (!this.imageRetryCount[s3FileGUID]) {
				this.imageRetryCount[s3FileGUID] = 0;
			}
			// Check if retry limit is reached
			if (this.imageRetryCount[s3FileGUID] < 2) {
				this.imageRetryCount[s3FileGUID]++;
				this.images[index].imageData = '';
				this.fetchImageData(s3FileGUID);
			}
		}
	}
	async fetchImageData(s3FileGUID: string) {
		let index = this.images.findIndex((t) => t.s3FileGUID == s3FileGUID);
		if (this.images[index].imageData == '') {
			let response = await this.s3FileService.downloadS3FilePreSignedUrl({
				s3FileGUID,
				createDownloadLink: false,
			});
			if (response?.file) {
				this.images[index].imageData = URL.createObjectURL(response.file);
			}
		}
	}
	openLightbox(imageUrl: string) {
		// this.currentImage = imageUrl;
		// this.currentImageIndex = this.images.indexOf(imageUrl); // Set current index
		// // Set current index
		// this.isLightboxOpen = true;
		// this.isZoomed = false; // Reset zoom state when opening lightbox
	}

	closeLightbox() {
		this.isLightboxOpen = false;
		this.currentImage = null;
		this.isZoomed = false; // Reset zoom state on close
	}

	toggleZoom(event: Event) {
		event.stopPropagation(); // Prevent closing the lightbox
		this.isZoomed = !this.isZoomed; // Toggle zoom state
	}
	onSelectionChanged(item: any) {
		if (item && item.addedItems.length > 0) {
			this.fetchImageData(item.addedItems[0].s3FileGUID ?? '');
		}
	}

	closeCarousel() {
		this.closeModal.emit();
	}
}
